
<div  class=" custom-modal-content" >
    <div class=" custom-modal-body">
       <div class="img-border">
        <img class="alert-icon" src="../../../../assets/icons/alert-triangle.svg" alt="">
       </div> 
        <h1 class=" custom-modal-title" id="exampleModalLabel"> {{ this.data.title}} ​</h1>
      <p class="custom-modal-text"> {{ this.data.description }} </p>
    </div>


    <div class=" custom-modal-footer">
            <button  mat-dialog-close class=" outlined-btn custom-text-annuler">Annuler</button>
          
            <button (click)="confirmDelete()" [disabled]="loading" [class]="{'filled-btn': true, 'disabled': loading }" class="mat-button custom-text-confirm">
              <span>Supprimer</span>
              <mat-icon *ngIf="loading" class="spinner"><mat-spinner mode="indeterminate" diameter="16"></mat-spinner></mat-icon> 
            </button>
    </div>   

  </div>



