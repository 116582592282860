import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-collab-profile-image',
  standalone: true,
  imports: [CommonModule],
  template: `
    <img [src]="imageSafeUrl" alt="avatar" *ngIf="imageSafeUrl">
  `,
  styleUrls: ['./collab-profile-image.component.scss']
})
export class CollabProfileImageComponent implements OnInit, OnDestroy {

  @Input() id ?: string

  imageSafeUrl: any;
  photoSubscription !: Subscription;

  constructor(
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    if (this.id) {
      this.getUserImage(this.id)
    }
  }

  getUserImage(id: string) {
    this.photoSubscription = this.userService.getUserPhoto(id).subscribe((imageBlob: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onloadend = () => {
        this.imageSafeUrl = reader.result?.toString();
      }
    })
  }

  ngOnDestroy(): void {
    this.photoSubscription?.unsubscribe()
  }
}
