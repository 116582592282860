import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { REQUESTS_BASE_URL } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NotificationCreatorService {

    constructor(
        private http: HttpClient,
    ) { }

    public getCategoryNotification(category: string): Observable<any> {
        return this.http.get<any>(`${REQUESTS_BASE_URL}/api/notification-manager/client-api/v1/notifications/type?category=${category}`);
    }


    typeId: string = "";
    description: string = `Votre nombre de jours de congés à consommer obligatoirement avant le 31/12/${new Date().getFullYear()} est x jours. Merci de planifier et saisir vos congés puis les faire valider par votre manager.`;
    operator: string | null = null;
    firstOperand: number = 0;
    secondOperand: number = 0;

    public updateparams(type: string = " ", op: string | null = null, firstOp: number = 0, secondOp: number = 0): void {
        this.typeId = type;
        this.operator = op;
        this.firstOperand = firstOp;
        this.secondOperand = secondOp;
    }



    public confirmNewStandardizeNotifCreation() {
        const payload = {
            typeId: this.typeId,
            description: this.description,
            operator: this.operator,
            firstOperand: this.firstOperand,
            secondOperand: this.secondOperand
        };
    
        return this.http.post<any>(`${REQUESTS_BASE_URL}/api/notification-manager/client-api/v1/new-notification/standardized`,
            payload, 
            {
                observe: 'response'
            });
    }


   public confirmNewNONStandardizeNotifCreation(payload: any){

    return this.http.post<any>(`${REQUESTS_BASE_URL}/api/notification-manager/client-api/v1/new-notification`, 
        payload, 
        {
            observe: 'response'
        });
  
}
}







