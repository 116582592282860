import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {TextEditorModule} from './components/text-editor/text-editor.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {WebiatRadioCardComponent} from './ui-kit/webiat-radio-card/webiat-radio-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WebiatTextInputComponent} from './ui-kit/webiat-text-input/webiat-text-input.component';
import {WebiatDatepickerComponent} from './ui-kit/webiat-datepicker/webiat-datepicker.component';
import {WebiatTimepickerComponent} from './ui-kit/webiat-timepicker/webiat-timepicker.component';


@NgModule({
  declarations: [
    WebiatRadioCardComponent,
    WebiatTextInputComponent,
    WebiatDatepickerComponent,
    WebiatTimepickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    TextEditorModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    MatDialogModule,


  ],
  exports: [
    WebiatRadioCardComponent,
    MatIconModule,
    MatRadioModule,
    WebiatTextInputComponent,
    WebiatDatepickerComponent,
    WebiatTimepickerComponent,
    MatProgressSpinnerModule,
    MatDialogModule,


  ]
})
export class SharedModule { }
