<div class="container">
    <div class="header">
        <div class="title-header">
            Notification non standardisée
        </div>
        <div class="button-header">
            <button [class]="{'outlined-btn': true}" (click)="returnToPreviousPage()">
                Annuler
            </button>

            <button [class]="{'filled-btn': true, 'disabled': confirmBtnDisabled }"
                [disabled]="isFormInvalid() || loading" (click)="openModalInf()" type="submit" class="submit-button">
                <div class="btn-icon">
                    <img src="../../../../assets/icons/send-icon.svg" alt="icon" />
                </div>
                Envoyer
                <mat-icon *ngIf="loading" class="spinner"><mat-spinner mode="indeterminate"
                        diameter="16"></mat-spinner></mat-icon>
            </button>
        </div>
    </div>

    <ng-container [formGroup]="formGroup">
        <div class="notification-creator-wrapper">
            <div class="notification-form-wrapper">
                <div class="notification-content">
                    <div class="title-first-section section-header">
                        <span class="section-title">Détails de la notification </span>
                    </div>
                    <div class="content-first-section">
                        <div class="first-row first-section-row">
                            <app-webiat-text-input class="first-section-input" [label]="'Titre de la notification'"
                                [placeholder]="'Insérez votre texte ici…'" [maxlength]="30"
                                [control]="castFormControl('titre')"
                                (inputChanged)="onInputChange($event, 'titre')"></app-webiat-text-input>
                            <app-webiat-drop-down class="first-section-dropdown" [label]="'Type de la notification'"
                                [data]="notificationTypes"
                                [control]="castFormControl('selectedNotificationType')"></app-webiat-drop-down>
                        </div>
                        <div class="second-row first-section-row">
                            <app-webiat-text-input class="first-section-input" [label]="'Corps de la notification'"
                                [placeholder]="'Insérez votre texte ici…'" [maxlength]="12" [isLengthLimited]=" false"
                                [control]="castFormControl('notificationContent')"
                                (inputChanged)="onInputChange($event, 'notificationContent')"></app-webiat-text-input>
                        </div>
                        <div class="third-row first-section-row">
                            <app-webiat-datepicker class="first-section-dropdown" [label]="'Date de l’envoi'"
                                [minDate]="currentDate"
                                [control]="castFormControl('sendingDate')"></app-webiat-datepicker>
                            <app-webiat-timepicker class="first-section-dropdown without-label"
                                [minTime]="getMinTimeForStartTime()" placeholder="hh:mm"
                                [control]="castFormControl('sendingTime')"></app-webiat-timepicker>
                        </div>
                    </div>
                </div>
                <div class="notification-destinator ">
                    <div class="header-2nd-section section-header">
                        <span class="section-title">Destinataire </span>
                        <span class="import-collab-list" *ngIf="(uploadedCollabList.length == 0 && selectedDestinators.length == 0)  "
                            (click)="openUploadModel()">
                            Importer une liste
                        </span>
                        <div class="collabs-nmbr" *ngIf="uploadedCollabList.length > 0 || (selectedDestinators.length > 0 && !selectedDestinators.includes('none')) " >
                            <img class="blue-bullet" src="./assets/icons/Ellipse-bleu.svg" alt=".">
                            <div class="count-collab" (click)="openCollabListDialog()">{{ selectedDestinators.length == 1 || uploadedCollabList.length == 1 ? '1 collaborateur' : selectedDestinators.length == 0 ? uploadedCollabList.length + ' collaborateurs' : selectedDestinators.length + ' collaborateurs' }}</div>
                            <div class="remove-btn-container" (click)="openConfirmationDialog()" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()">
                                <img src="./assets/icons/x-close.svg" alt="remove" id="remove-list-img"  >
                                <div id="tooltip" [style.display]="tooltipVisible ? 'block' : 'none'">Supprimer la liste</div>
                              </div>
                        </div>
                    </div>
                    <ng-container *ngIf="uploadedCollabList.length == 0 ,else uploadedList">
                        <div class="search-bar">
                            <div class="icon-wrapper-display">
                                <img src="./assets/images/lope-icon.png" alt="lope">
                            </div>
                            <input class="search-input-text" #inputFilter type="text" placeholder="Chercher un collaborateur…" />
                            <div class="icon-wrapper-cancel" *ngIf="searchText.length !=0" (click)="resetSearchField()">
                                <img src="./assets/icons/cancel-icon.svg" alt="cancel">
                            </div>
                        </div>
                        <div class="collab-list-wrapper">
                            <div class="all-collab-selector collab-wrapper" *ngIf="searchText.length == 0">
                                <div class="collab-info-wrapper">
                                    <mat-icon class="image-wrapper" svgIcon="selectAllIcon"></mat-icon>
                                    <span class="name">Tous les collaborateurs BIAT</span>
                                </div>
                                <button [class.action-btn]="!isAllSelected" [class.unselect-btn]="isAllSelected"
                                    (click)="selectAllCollaborators()">
                                    <span [class.btn-content]="!isAllSelected"
                                        [class.unselect-btn-content]="isAllSelected">{{ isAllSelected ? 'Déselectionner tout' : 'Sélectionner tout' }}</span>
                                </button>
                            </div>
                            <div class="collabs" #collabsContainer infiniteScroll (scrolled)="onScroll()" [scrollWindow]="false" *ngIf="!isSearchLoading && collaboratorsList.length != 0">
                                    <div class="list collab-wrapper" *ngFor="let collaborator of collaboratorsList">
                                        <div class="collab-info-wrapper">
                                            <div class="image-wrapper">
                                                <app-collab-profile-image [id]="collaborator.id"></app-collab-profile-image>
                                            </div>
                                            <div class="collab-info">
                                                <div class="name">{{collaborator?.firstname}} {{collaborator?.lastname}}
                                                </div>
                                                <div class="department">
                                                    {{collaborator?.bankExperienceList[0]?.assignment?.name}}</div>
                                            </div>
                                        </div>
                                        <button (click)="toggleCollaboratorSelection(collaborator)"
                                            [ngClass]="{ 'action-btn': !selectedDestinators.includes(collaborator.id),'unselect-btn': selectedDestinators.includes(collaborator.id)}">
                                            <span
                                                [ngClass]="{ 'unselect-btn-content': selectedDestinators.includes(collaborator.id),'btn-content': !selectedDestinators.includes(collaborator.id)}">
                                                {{ selectedDestinators.includes(collaborator.id)? 'Déselectionner' : 'Sélectionner'}}
                                            </span>
                                        </button>
                                    </div>
                                    <div class="spiner-wrapper" *ngIf="areCollabLoading">
                                        <mat-spinner [diameter]="24"></mat-spinner>
                                    </div>
                            </div>
                            <div class="search-spiner-wrapper spiner-wrapper" *ngIf="isSearchLoading">
                                <mat-spinner [diameter]="40"></mat-spinner>
                                <span class="search-loading-text">En cours de recherche…</span>
                            </div>
                            <div class="empty-list-container" *ngIf="collaboratorsList.length == 0 && searchText.length !=0 && !isSearchLoading">
                                <div class="icon-wrapper-display">
                                    <img src="./assets/images/empty-list.png" alt="empty-list">
                                </div>
                                <div class="message-title">
                                    Aucun résultat trouvé
                                </div>
                                <div class="message-sub-title">
                                    Veuillez réessayer de saisir un autre mot pour rechercher un collaborateur.
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="notification-simulator">

            </div>
        </div>
    </ng-container>

</div>

<ng-template #uploadedList>
    <div class="list-container">
        <div class="group-image">
            <div [ngClass]="{
                'image-container-one': uploadedCollabList.length == 1 ,
                'image-container-two-1': uploadedCollabList.length == 2 ,
                'image-container-1': uploadedCollabList.length >=3}">
                <app-collab-profile-image [id]="uploadedCollabList[0]"></app-collab-profile-image>
            </div>
            <div [ngClass]="{
                'image-container-two-2': uploadedCollabList.length == 2 ,
                'image-container-2': uploadedCollabList.length >= 3}" *ngIf=" uploadedCollabList.length >=2">
                <app-collab-profile-image [id]="uploadedCollabList[1]"></app-collab-profile-image>
            </div>
            <div class="image-container-3" *ngIf="uploadedCollabList.length >= 3">
                <app-collab-profile-image [id]="uploadedCollabList[2]"></app-collab-profile-image>
            </div>
        </div>
        <div class="info-list">
            <div class="file-name">
                {{uploadedFileName}}
            </div>
            <div class="collab-number">
                {{uploadedCollabList.length}} {{ uploadedCollabList.length > 1 ? 'Collaborateurs' : 'Collaborateur' }}
            </div>
        </div>
        <div class="button-delete" (click)="resetImportedList()">
            <img src="../../../../assets/images/trash-3.png" alt="trash">
        </div>

    </div>
</ng-template>
