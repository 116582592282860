import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserProfilService} from 'src/app/services/user-profil.service';
import {catchError, of} from "rxjs";

@Component({
  selector: 'app-selected-collab-list-modal',
  templateUrl: './selected-collab-list-modal.component.html',
  styleUrls: ['./selected-collab-list-modal.component.scss']
})
export class SelectedCollabListModalComponent implements OnInit {
  selectedDestinators: string[] = [];
  uploadedCollabList: any[] = [];
  removedFromList: any[] = [];
  collaboratorsList: any[] = [];
  CollabsToRemoveList: string[] = [];
  areCollabLoading: boolean = false;
  selectedDestinatorsNumber: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedDestinators: string[], uploadedCollabList: any[] },
    public dialogRef: MatDialogRef<SelectedCollabListModalComponent>,
    private userProfilService: UserProfilService,
  ) {
    this.selectedDestinators = data.selectedDestinators;
    this.uploadedCollabList = data.uploadedCollabList
  }

  ngOnInit(): void {
    this.fetchCollaboratorDetails();
  }

  fetchCollaboratorDetails(): void {
    this.areCollabLoading = true;
    if (this.uploadedCollabList.length == 0) {
      this.selectedDestinatorsNumber = this.selectedDestinators.length
      this.selectedDestinators.forEach(collabId => {
        this.userProfilService.getCollaboratorById(collabId).pipe(
          catchError((error: any) => {
            console.error(`Error fetching collaborator info for ID ${collabId}:`, error);
            return of(null);
          })
        ).subscribe(
          (res: any) => {
            const collaboratorInfo = {
              id: collabId,
              name: `${res.firstname} ${res.lastname}`,
              assignment: res.bankExperienceList[0]?.assignment?.name
            };
            this.collaboratorsList.push(collaboratorInfo);
            this.areCollabLoading = false;
          }
        );
      });
      console.log('users', this.collaboratorsList);

    } else {
      this.selectedDestinatorsNumber = this.uploadedCollabList.length
      this.uploadedCollabList.forEach(collabId => {
        this.userProfilService.getCollaboratorById(collabId).pipe(
          catchError((error: any) => {
            console.error(`Error fetching collaborator info for ID ${collabId}:`, error);
            return of(null);
          }
        )).subscribe(
          (res: any) => {
            const collaboratorInfo = {
              id: collabId,
              name: `${res.firstname} ${res.lastname}`,
              assignment: res.bankExperienceList[0]?.assignment?.name
            };
            this.collaboratorsList.push(collaboratorInfo);
            this.areCollabLoading = false;
          }
        );
      });
    }

  }

  toggleSelection(collabId: string) {
    const index = this.CollabsToRemoveList.indexOf(collabId);

    if (index !== -1) {
      this.CollabsToRemoveList.splice(index, 1);
      this.selectedDestinatorsNumber++;
    } else {
      this.CollabsToRemoveList.push(collabId);
      this.selectedDestinatorsNumber--;
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onApproveClick(): void {
    this.dialogRef.close(this.CollabsToRemoveList);
  }

}
