import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './shared/layouts/dashboard/dashboard.component';
import { KeycloakAngularModule, KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AppAuthGuard } from './core/guards/auth.guard';
import { environment } from 'src/environments/environment';
import { WebiatDrawerComponent } from './shared/components/webiat-drawer/webiat-drawer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {WebiatDashboardContentComponent} from './features/webiat-dashboard-content/webiat-dashboard-content.component';
import {WebiatCardCreatorComponent} from './features/webiat-card-creator/webiat-card-creator.component';
import {WebiatDropDownComponent} from './shared/ui-kit/webiat-drop-down/webiat-drop-down.component';
import {WebiatLogoutComponent} from './shared/components/webiat-logout/webiat-logout.component';
import {WebiatAccessDeniedComponent} from './features/webiat-access-denied/webiat-access-denied.component';
import {WebiatFileUploadComponent} from './shared/ui-kit/webiat-file-upload/webiat-file-upload.component';
import {TextEditorModule} from './shared/components/text-editor/text-editor.module';
import {PreviewComponent} from './shared/ui-kit/preview/preview.component';
import {WebiatToasterComponent} from './shared/ui-kit/webiat-toaster/webiat-toaster.component';
import {DeviceSimulatorComponent} from './shared/ui-kit/device-simulator/device-simulator.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {WebiatmodalComponent} from './shared/webiatmodal/webiatmodal.component';
import {ModalComponent} from './shared/components/modal/modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  NotificationTypeComponent
} from './features/webbiat-notification-creator/notification-type/notification-type.component';
import {
  StandardNotificationComponent
} from './features/webbiat-notification-creator/standard-notification/standard-notification.component';
import {SharedModule} from './shared/shared.module';
import localeFr from '@angular/common/locales/fr';
import {
  InformativeModalComponent
} from './features/webbiat-notification-creator/informative-modal/informative-modal.component';
import {
  NonStandardNotificationComponent
} from './features/webbiat-notification-creator/non-standard-notification/non-standard-notification.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {
  CollabProfileImageComponent
} from "./shared/components/collaborator/collaborator-profile-image/collab-profile-image.component"
import {TokenExpiredInterceptorInterceptor} from "./core/interceptors/token-expired-interceptor-interceptor.service";
import {
  SelectedCollabListModalComponent
} from './features/webbiat-notification-creator/non-standard-notification/selected-collab-list-modal/selected-collab-list-modal.component';

registerLocaleData(localeFr, 'fr');

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    WebiatDrawerComponent,
    WebiatDashboardContentComponent,
    WebiatCardCreatorComponent,
    WebiatDropDownComponent,
    WebiatLogoutComponent,
    WebiatAccessDeniedComponent,
    WebiatFileUploadComponent,
    WebiatToasterComponent,
    WebiatmodalComponent,
    ModalComponent,
    NotificationTypeComponent,
    StandardNotificationComponent,
    InformativeModalComponent,
    NonStandardNotificationComponent,
    SelectedCollabListModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    TextEditorModule,
    PreviewComponent,
    ReactiveFormsModule,
    MatSidenavModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatExpansionModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    NgbModule,
    SharedModule,
    InfiniteScrollModule,
    CollabProfileImageComponent,
    DeviceSimulatorComponent
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptorInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    AppAuthGuard,
    DatePipe,
  ],
  entryComponents: [AppComponent],
})
export class AppModule {
  async ngDoBootstrap(app: any) {
    try {
      await keycloakService.init({
        config: {
          url: environment.KEYCLOAK_ADMIN_CONFIG.URL,
          realm: environment.KEYCLOAK_ADMIN_CONFIG.REALM,
          clientId: environment.KEYCLOAK_ADMIN_CONFIG.CLIENT_ID,
        },
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: true,
        },
        bearerPrefix: 'Bearer ',
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: true,
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}
