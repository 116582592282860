import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CollaboratorService} from 'src/app/services/collaborator.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe, Location} from '@angular/common';
import {fromEvent, Subscription} from 'rxjs';
import {NotificationCreatorService} from 'src/app/services/notification-creator.service';
import {CollaboratorViewModel} from 'src/app/shared/viewModels/collaborator.viewModel';
import {MatDialog} from '@angular/material/dialog';
import {WebiatmodalComponent} from 'src/app/shared/webiatmodal/webiatmodal.component';
import {ModalService} from 'src/app/services/modal.service';
import {InformativeModalComponent} from '../informative-modal/informative-modal.component';
import {SelectedCollabListModalComponent} from './selected-collab-list-modal/selected-collab-list-modal.component';
import {DeleteModalComponent} from 'src/app/shared/ui-kit/delete-modal/delete-modal.component';
import {debounceTime, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-non-standard-notification',
  templateUrl: './non-standard-notification.component.html',
  styleUrls: ['./non-standard-notification.component.scss']
})
export class NonStandardNotificationComponent implements OnInit, OnDestroy {

  currentDate: Date = new Date(Date.now());
  formGroup: FormGroup = new FormGroup({});
  loading: boolean = false;
  notificationTypes: any[] = [];
  confirmBtnDisabled: boolean = true;
  searchFieldLength: number = 0;

  collaboratorsList: CollaboratorViewModel[] = [];
  areCollabLoading: boolean = false;
  currentPage: number = 0;
  collabPerPage: number = 20;
  isDestinationSelected: boolean = false;
  selectedDestinators: string[] = [];


  isAllSelected: boolean = false;
  selectedCollaborators: { [key: string]: boolean } = {};

  _collaboratorListSubscription$ !: Subscription;
  _uploadedFileSubscription$ !: Subscription;
  uploadedCollabList: any[] = [];
  uploadedFileName !: string
  _subscriptionList$: Array<Subscription> = []

  tooltipVisible: boolean = false;
  searchText: string = '';
  isSearchLoading: boolean = false;
  @ViewChild('inputFilter', {static: false}) input !: ElementRef;
  @ViewChild('collabsContainer', {static: false}) collabsContainer!: ElementRef;

  constructor(
    private router: Router, private formBuilder: FormBuilder,
    private datePipe: DatePipe, private notificationCreatorService: NotificationCreatorService,
    private collaboratorService: CollaboratorService,
    public dialog: MatDialog, private modalService: ModalService,
    private elementRef: ElementRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private location: Location
  ) {
    iconRegistry.addSvgIcon(
      'selectAllIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/webiat-avatar.svg')
    );

    this.notificationCreatorService.getCategoryNotification('NON STANDARDIZED').subscribe(types => {
      this.notificationTypes = types;
    });

    this.formGroup = this.formBuilder.group(
      {
        titre: ['', Validators.required],
        selectedNotificationType: ['', Validators.required],
        notificationContent: ['', Validators.required],
        sendingDate: [this.currentDate, Validators.required],
        sendingTime: ['', Validators.required],
        destinator: [[], Validators.required],
      }
    );
  }

  ngAfterViewInit(): void {
    this.setupSearchSubscription();
  }

  ngOnInit(): void {
    this.loadCollaboratorsList();
  }

  setupSearchSubscription(): void {
    fromEvent((this.input.nativeElement as HTMLElement), 'input')
      .pipe(
        map((event: Event) => (event.target as HTMLInputElement).value.trim()),
        tap(value => {
          this.searchFieldLength = value.length;
          this.searchText = value;
          this.currentPage = 0;
          this.isSearchLoading = true;
        }),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.loadCollaboratorsList();
      });
  }

  returnToPreviousPage(): void {
    this.location.back();
  }

  public onSubmit(): void {

  }

  public isFormInvalid(): boolean {

    const invalid = this.formGroup.invalid || this.formGroup.pending
    this.confirmBtnDisabled = invalid
    return invalid;

  }

  public castFormControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }

  public onInputChange(value: string, controlName: string): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(value);
      control.markAsDirty();
      control.updateValueAndValidity();
    }
  }

  private getDateWithoutTime(dateValue: Date): Date {
    if (dateValue) {
      return new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate()
      );
    }
    return new Date();
  }

  public getMinTimeForStartTime(): any {
    const sendingDateValue: Date = this.formGroup.get('sendingDate')?.value;
    if (sendingDateValue) {
      const sendingDateWithoutTime: Date =
        this.getDateWithoutTime(sendingDateValue);
      const currentDateWithoutTime: Date = this.getDateWithoutTime(
        this.currentDate
      );
      if (sendingDateWithoutTime.getTime() === currentDateWithoutTime.getTime()) {
        return this.datePipe.transform(this.currentDate, 'hh:mm a');
      }
    }
  }

  resetSearchField() {
    this.input.nativeElement.value = '';
    this.searchText = '';
    this.currentPage = 0;
    this.isSearchLoading = true;
    this.loadCollaboratorsList();
    this.collabsContainer.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
  }

  toggleCollabLoading = () => this.areCollabLoading = !this.areCollabLoading;

  loadCollaboratorsList(): void {
    if (!this.areCollabLoading) {
      this.areCollabLoading = true;
      let requestObservable: any;

      if (this.searchText.trim() !== '') {
        requestObservable = this.collaboratorService.getSearchedCollabList(this.currentPage, this.collabPerPage, this.searchText);
      } else {
        requestObservable = this.collaboratorService.getCollaboratorList(this.currentPage, this.collabPerPage);
      }

      requestObservable.subscribe(
        (res: any) => {
          if (this.currentPage === 0) {
            this.collaboratorsList = res.userAdvancedResponseBody;
          } else {
            this.collaboratorsList = [...this.collaboratorsList, ...res.userAdvancedResponseBody];
          }
          this.areCollabLoading = false;
          this.isSearchLoading = false;
        },
        () => {
          this.areCollabLoading = false;
          this.isSearchLoading = false;
        }
      );
    }
  }


  public onScroll(): void {
    this.currentPage++;
    this.loadCollaboratorsList()
  }


  selectAllCollaborators(): void {
    this.isAllSelected = !this.isAllSelected;

    if (this.isAllSelected) {

      this.selectedDestinators = ['none'];
      for (const collaborator of this.collaboratorsList) {
        this.selectedCollaborators[collaborator.id] = false;
      }
      const destinatorControl = this.formGroup.get('destinator');
      if (destinatorControl) {
        destinatorControl.setValue(this.selectedDestinators);
        this.isFormInvalid();
      }
    } else {

      this.selectedDestinators = [];
      for (const collaborator of this.collaboratorsList) {
        this.selectedCollaborators[collaborator.id] = false;
      }

      const destinatorControl = this.formGroup.get('destinator');
      if (destinatorControl) {
        destinatorControl.setValue(this.selectedDestinators);
        this.isFormInvalid();
      }
    }


  }

  openUploadModel() {
    const dialogRef = this.dialog.open(WebiatmodalComponent, {
      width: '480px',
      height: '322px',
      minHeight: '268px'
    });
    dialogRef.componentInstance.target = 'create-notif'
    this.modalService.setDialogRef(dialogRef);

    this._collaboratorListSubscription$ = dialogRef.componentInstance.getCollabListResponse.subscribe(
      res => {
        this.uploadedCollabList = res.userList
        this.formGroup.patchValue({destinator: this.uploadedCollabList})
      }
    )
    this._subscriptionList$.push(this._collaboratorListSubscription$)

    this._uploadedFileSubscription$ = dialogRef.componentInstance.UploadedFile.subscribe(
      res => {
        this.uploadedFileName = res.name
      }
    )
    this._subscriptionList$.push(this._uploadedFileSubscription$)
  }

  resetImportedList() {
    this.uploadedCollabList = [];
    this.formGroup.patchValue({destinator: []})
  }

  ngOnDestroy(): void {
    this._subscriptionList$.forEach((_sub: Subscription) => {
      _sub.unsubscribe()
    })
  }


  toggleCollaboratorSelection(collaborator: CollaboratorViewModel): void {

    if (this.isAllSelected) {
      this.isAllSelected = false
      this.selectedDestinators = [];
      const destinatorControl = this.formGroup.get('destinator');
      if (destinatorControl) {
        destinatorControl.setValue(this.selectedDestinators);
        this.isFormInvalid();
      }
    }

    this.selectedCollaborators[collaborator.id] = !this.selectedCollaborators[collaborator.id];

    const destinatorControl = this.formGroup.get('destinator');
    if (destinatorControl) {
      this.selectedDestinators = destinatorControl.value as string[];
      const collaboratorId = collaborator.id;
      const index = this.selectedDestinators.indexOf(collaboratorId);
      if (index !== -1) {
        this.selectedDestinators.splice(index, 1);
      } else {
        this.selectedDestinators.push(collaboratorId);
      }
      destinatorControl.setValue(this.selectedDestinators);
      this.isDestinationSelected = this.selectedDestinators.length > 0;
      this.isFormInvalid();
    }

  }

  openModalInf() {
    const dialogRef = this.dialog.open(InformativeModalComponent, {
      data: {
        componentName: 'nonStandard',
        formContent: this.formGroup
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.closeAll();
      }
    });

    this.modalService.setDialogRef(dialogRef);
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:unload', ['$event'])
  @HostListener('window:hashchange', ['$event'])
  unloadNotification($event: any) {
    if (this.shouldShowConfirmation()) {


      this.showAlert();
      $event.preventDefault();
    }
  }

  showAlert() {
    window.alert('Are you sure you want to reload? Your changes may not be saved.');
  }


  private shouldShowConfirmation(): boolean {
    const formValues = this.formGroup.getRawValue();
    return (
      formValues.titre ||
      formValues.selectedNotificationType ||
      formValues.notificationContent ||
      formValues.sendingTime ||
      formValues.destinator


    );
  }

  showTooltip() {

    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;

  }

  openCollabListDialog() {
    const dialogRef = this.dialog.open(SelectedCollabListModalComponent, {
      data: {
        uploadedCollabList: this.uploadedCollabList,
        selectedDestinators: this.selectedDestinators
      },
    });
    dialogRef.afterClosed().subscribe((result: any[]) => {

      if (this.uploadedCollabList.length == 0) {
        result.forEach(collab => {
          const index = this.selectedDestinators.indexOf(collab);
          if (index !== -1) {
            this.selectedDestinators.splice(index, 1)
          }
        });

        if (this.selectedDestinators.length == 0) {
          this.selectedDestinators = []
          this.formGroup.patchValue({destinator: this.selectedDestinators})
        }
      } else {
        result.forEach(collab => {
          const index = this.uploadedCollabList.indexOf(collab);
          if (index !== -1) {
            this.uploadedCollabList.splice(index, 1)
          }
        });
        if (this.uploadedCollabList.length == 0) {
          this.resetImportedList()
        }
      }
    });

    this.modalService.setDialogRef(dialogRef);
  }

  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        title: 'Êtes-vous sûr de vouloir supprimer la liste?​',
        description: 'Une fois supprimée, la liste des collaborateurs revient à son état initial sans aucune sélection appliquée.'
      }
    });

    dialogRef.componentInstance.publicationDeleted.subscribe((deleted: boolean) => {
      if (deleted) {
        if (this.uploadedCollabList.length == 0) {
          this.selectedDestinators = []
          this.formGroup.patchValue({destinator: this.selectedDestinators})
        } else {
          this.resetImportedList()
        }
      }
    });
  }

}

