<div class="loading-spinner-container" *ngIf="editCard && (isLoadingCardInfo || isLoadingMedia)">

  <div class="spiner-wrapper">
      <mat-spinner [diameter]="48"></mat-spinner>
  </div>
  
  <div class="loading-label">En cours de recherche…</div>
</div>
<ng-container [formGroup]="formGroup" *ngIf="!editCard || (!isLoadingCardInfo && !isLoadingMedia )">
  <div class="card-creator-container">
    <header class="header">
      <div class="header-left-content">
        <button mat-mini-fab>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title-date-container">
          <span>{{
            formGroup.get("titre")!.value || "Titre de la publication"
          }}</span>
          <span class="date"
            >Aujourd'hui à {{ currentDate | date : "HH:mm" }}</span
          >
        </div>
      </div>
      <div class="header-buttons">
        <button mat-stroked-button>Annuler</button>
        <button
          mat-raised-button
          type="submit"
          (click)="editCard ? updateNews() :  onSubmit()"
          class="submit-button"
          [disabled]=" editCard  ? isDisabled  :( isFormInvalid() || loading)"
        >
          <span>Publier</span>
          <mat-icon *ngIf="loading" class="spinner"
            ><mat-spinner mode="indeterminate" diameter="16"> </mat-spinner
          ></mat-icon>
        </button>
      </div>
    </header>
    <div class="content-container">
      <div class="scrollable-content">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="expansion-title"
              >Entête de la carte</mat-expansion-panel-header
            >
            <div class="expansion-content">
              <app-webiat-text-input
                [label]="'Titre'"
                [placeholder]="'Titre'"
                [maxlength]="60"
                [control]="castFormControl('titre')"
                (inputChanged)="onInputChange($event, 'titre')"
              ></app-webiat-text-input>
              <app-webiat-drop-down
                [label]="'Catégorie'"
                [data]="categoryList"
                [control]="castFormControl('category')"
                [selectedItem]="formGroup.get('category')?.value"
              ></app-webiat-drop-down>

              <app-webiat-datepicker
                [label]="'Date de la publication'"
                [maxDate]="
                  formGroup.get('endDate')?.value
                    ? formGroup.get('endDate')?.value
                    : null
                "
                placeholder="JJ/MM/AAAA"
                [control]="castFormControl('startDate')"
              ></app-webiat-datepicker>
              
              <app-webiat-timepicker
                class="without-label"
                [maxTime]="
                  formGroup.get('endTime')?.value &&
                  formGroup.get('startDate')?.value ===
                    formGroup.get('endDate')?.value
                    ? formGroup.get('endTime')?.value
                    : null
                "
                placeholder="hh:mm"
                [control]="castFormControl('startTime')"
              ></app-webiat-timepicker>
              <app-webiat-datepicker
                [label]="'Date de l’expiration'"
                [minDate]="
                  formGroup.get('startDate')?.value
                    ? formGroup.get('startDate')?.value
                    : currentDate
                "
                placeholder="JJ/MM/AAAA"
                [control]="castFormControl('endDate')"
              ></app-webiat-datepicker>
              <app-webiat-timepicker
                class="without-label"
                [minTime]="getMinTimeForEndTime()"
                placeholder="hh:mm"
                [control]="castFormControl('endTime')"
              ></app-webiat-timepicker>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="expansion-title"
              >Corps de la publication</mat-expansion-panel-header
            >
            <div class="corp-expansion-content">
              <div class="sub-container">
                <label>Contenu de la publication</label>
                <app-text-editor
                  [inputText]="castFormControl('description')"
                  (_outputText)="previewData = $event"
                ></app-text-editor>
              </div>
              <div class="sub-container">
                <label>Pièce jointe</label>

                <app-webiat-radio-card
                  [data]="radioButtons"
                  (selectedValueChanged)="onSelectedValueChanged($event)"
                ></app-webiat-radio-card>
                
              </div>
              <div class="sub-container">
                <app-webiat-file-upload
                  [DocVideoFiles]="selectedDocVideoFiles"
                  [ImageFiles]="selectedImageFiles"
                  [isNewCard]="isNewCard"
                  [filesType]="filesType"
                  [multiple]="true"
                  (limitExceeded)="handleLimitExceeded($event)"
                  (imageTypeError)="handleImageTypeError($event)"
                  (fileCombinationViolation)="handleFileCombinationViolation($event)"
                  (fileChanges)="handleFileChanges($event)"
                  >
                  <div class="content-text-wrapper">
                    <label for="fileDropRef">Cliquer ici pour parcourir et télécharger</label><br>
                  </div>
                </app-webiat-file-upload>

              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="expansion-title"
              >Les Réactions</mat-expansion-panel-header
            >
            <div class="reaction-wrapper">
              <label
                for="toggle"
                class="toggle-label"
                (click)="onReactionClick()"
              >
                <div class="info-panel">
                  <div class="icon-div">
                    <mat-icon svgIcon="heart"></mat-icon>
                  </div>
                  <div class="reaction-texts">
                    <span>Activation des réactions</span>
                    <p>
                      Lorsque vous cliquez sur le bouton suivant vous acceptez
                      l’activation des réactions sur votre publication
                    </p>
                  </div>
                </div>
              </label>
              <mat-slide-toggle
                id="toggle"
                [checked]="checked"
                #toggle
                formControlName="interaction"
                (change)="onToggleChange($event)"
              ></mat-slide-toggle>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="preview">
        <app-device-simulator
          [uploadedFile]="allFilesUploaded"
          [reactionStatus]="checked"
          [formValue]="emitedFormValue"
          [displayLoading] = "false"
        >
          <span class="previewPlaceholeder" *ngIf="!previewData">
            {{ previewPlaceholder }}
          </span>
          <app-preview [data]="previewData"></app-preview>
        </app-device-simulator>
      </div>
    </div>
  </div>
</ng-container>
