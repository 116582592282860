import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WebiatToaster } from './webiat-toaster.viewModel';
import { Observable } from 'rxjs/internal/Observable';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-webiat-toaster',
  templateUrl: './webiat-toaster.component.html',
  styleUrls: ['./webiat-toaster.component.scss']
})
export class WebiatToasterComponent implements OnInit {
  toastersStack$!: Observable<WebiatToaster[]>;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public toasterService: ToasterService ) {
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    );
    iconRegistry.addSvgIcon(
      'red_close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/red_close.svg')
    );
    iconRegistry.addSvgIcon(
      'error',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/error.svg')
    );
    iconRegistry.addSvgIcon(
      'success',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/success.svg')
    );
   }

  ngOnInit(): void {
    this.toastersStack$ = this.toasterService.getToasters();
  }

  public dismiss(toaster: WebiatToaster): void {        
    this.toasterService.closeToaster(toaster);  
  }

}
