import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {REQUESTS_BASE_URL} from 'src/environments/environment';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class UserProfilService {
  userId: string = '';



  constructor(
    private http: HttpClient,
  ) { }

  public setUserId(userId: string) {
    this.userId = userId;
    localStorage.setItem('userId', userId); // Stocker dans le localStorage
  }

  public getUserId(): string {
    if (this.userId!=='') {
      return this.userId;
    }else {
      return localStorage.getItem('userId') || '';
    }
  }

  public clearUserId() {
    this.userId = '';
    localStorage.removeItem('userId'); // Effacer du localStorage
  }


  public getCollaboratorById(userId: string) {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/info/${userId}`);
  }

  getCollabInfoCvById(id : string){
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/user-profile/client-api/v1/user-profile/cv-by-id`,
      { params });
  }

  public getCollabtrainingById(userId: string) {
    return this.http.get<any>(`${REQUESTS_BASE_URL}/api/training/client-api/v1/training/user/${userId}`);
  }

  updateImage(file: File, id: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.put(`${REQUESTS_BASE_URL}/api/file-manager/client-api/v1/updateImage/${id}`, formData, {
      responseType: 'text'
    })
  }
}
