<div class="view-wrapper">

    <div class="container">
        <div class="wrapper">
            <h3 class="title">
                Aperçu de la publication
            </h3>
            <div class="iphone_frame">
                <div class="highlited_stroke"></div>
                <div class="dead_area"></div>
                <div class="iphone_buttons">
                    <div class="left_iphone_button">
                      <div class="options"></div>
                      <div class="button"></div>
                    </div>
                    <div class="right_iphone_button">
                      <div class="options"></div>
                      <div class="button"></div>
                    </div>
                </div>
                <div class="main_screen">
                    <div class="__status_bar">
                        <div class="__left-panel">
                          <div class="__time">{{(currentTime$ | async )| date:'HH:mm' }}</div>
                        </div>
                        <div class="dynamic_island"></div>
                        <div class="__right-panel">
                          <div class="__wifi">
                              <img src = "../../../../assets/icons/mobile-signal-icon.svg" alt="battery-icon"/>
                              <img src = "../../../../assets/icons/wifi-icon.svg" alt="battery-icon"/>
                              <img src = "../../../../assets/icons/battery-icon.svg" alt="battery-icon"/>
                          </div>
                        </div>
                    </div>
                    <div class="header_location">
                        <div class="acceuil_title">
                            <img src="../../../../assets/images/preview-logo.png" alt="logo">
                        </div>
                        <div class="notif-faq-container">

                            <div class="notification-wrapper">
                                <img src="../../../../assets/images/notification-icon.png" alt="notification">
                            </div>
                            <div class="faq_title">
                                <img src="../../../../assets/images/FAQ-icon.png" alt="FAQ">
                            </div>
                        </div>
                    </div>
                    <div class="name_category">
                        {{categoryTitle}} 
                    </div>

                    <div class="spinner-wrapper" *ngIf="displayLoading && isLoadingDetails">
                        <mat-spinner [diameter]="48"> </mat-spinner> 
                        <span class="search-loading-text">En cours de recherche…</span>
                    </div>
                    <div class="carte_wrapper" *ngIf="!displayLoading || !isLoadingDetails">
                        <div class="carte_header">
                            <div [ngClass]="iconNameImage.length !=0 ? 'icon_carte_shadow' : 'icon_carte' "  >
                                <img src = '../../../../assets/images/{{iconNameImage}}'  alt="icon" *ngIf="iconNameImage.length !=0 ; else empty"/>
                                <ng-template #empty>
                                    <img src = '../../../../assets/images/empty_category.png' alt="icon"/>
                                </ng-template>
                            </div>
                            <div class="carte_category">
                                <div class="goupe_name">
                                    {{(formValue?.titre && formValue?.titre.length!=0 ) ?  formValue?.titre : 'Titre de la publication' }}
                                </div>
                                <div class="publish_time">
                                    Le {{ formatDateString(formValue?.startDate) || 'JJ MMM AAAA' }}
                                </div>
                            </div>
                        </div>
                        <div class="text-container">
                            <ng-content></ng-content>
                        </div>


                        <div class="spinner" *ngIf="displayLoading && isLoadingMedia">
                            <mat-spinner [diameter]="48"> </mat-spinner> 
                            <span class="search-loading-text">En cours de recherche…</span>
                        </div>

                        <div class="media-wapper">

                            <div class="img-video-wrapper">
    
                                <figure class="image-container" *ngIf="imageIsExist">
                                    <div class="image-counter">1 / {{imageList.length}}</div>
                                    <img class="image_src" src={{imageList[0].url}} alt="iamge">
                                </figure>
                                <video controls autoplay [muted]="'muted'" loop class="video-container" *ngIf="videoIsExist">
                                    <source src={{videoList[0].url}} type="video/mp4" />
                                </video>
                            </div>
                            <div class="doc_wrapper" *ngIf="docIsExist">
                                <div  *ngFor="let doc of docList" class="wrapper_element" >
                                    <hr/>
                                    <div class="file_element">
                                        <div [ngClass]="getFileNameType(doc.file.name)[1] == 'pdf' ? 'icon_doc_pdf' :'icon_doc'">
                                            <img src="../../../../assets/images/{{getFileNameType(doc.file.name)[1] == 'pdf' ? 'pdf_doc_icon.png' :'doc_icon.png'}}" alt="doc-icon"> 
                                        </div>
                                        <div class="name_type_doc">
                                            <div class="name_doc">
                                                {{getFileNameType(doc.file.name)[0] }}
                                            </div>
                                            <div class="type_size_doc">
                                                Type: .{{getFileNameType(doc.file.name)[1]}} / {{formatBytes(doc?.file?.size ?? 0)}}
                                            </div>
                                        </div>
                                        <div class="download_icon">
                                            <img src="../../../../assets/images/download-icon.png" alt="download">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="reaction_wrapper" *ngIf="reactionStatus"></div>
                    </div>
                    <div class="carte_wrapper2"></div>  
                </div>
            </div>

        </div>
    </div>
</div>