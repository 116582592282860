import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {KeycloakService} from 'keycloak-angular';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {UserService} from 'src/app/services/user.service';
import {WebiatLogoutComponent} from '../webiat-logout/webiat-logout.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NavigationService} from 'src/app/services/navigation.service';
import {filter} from 'rxjs';

interface NavItem {
  name: string;
  routerLink: string;
  icon: string;
  activeIcon: string;
}

@Component({
  selector: 'app-webiat-drawer',
  templateUrl: './webiat-drawer.component.html',
  styleUrls: ['./webiat-drawer.component.scss'],
})
export class WebiatDrawerComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public readonly navList: NavItem[] = [
    { name: 'Gestion de contenu', routerLink: 'content-managment', icon: 'postsGrey', activeIcon: 'posts' },
    { name: 'Gestion des collaborateurs', routerLink: 'collaborator-list', icon: 'users', activeIcon: 'users-active' },
  ];
  public agentFirstName!: string;
  public agentLastName!: string;
  public role!: string;
  public imageUrl!: string;
  private id!: string;
  public showButtonLogout: boolean = false;

  constructor(
    private userService: UserService,
    private keycloakAngular: KeycloakService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public navService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    iconRegistry.addSvgIcon(
      'switchIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/switch-icons.svg')
    );
    iconRegistry.addSvgIcon(
      'posts',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/posts.svg')
    );
    iconRegistry.addSvgIcon(
      'users',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/users.svg')
    );
    iconRegistry.addSvgIcon(
      'users-active',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/users-active.svg')
    );
    iconRegistry.addSvgIcon(
      'postsGrey',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/posts-grey.svg')
    );
    iconRegistry.addSvgIcon(
      'logout',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logout.svg')
    );
  }

  async ngOnInit(): Promise<void> {
    const isAuthenticated = await this.keycloakAngular.isLoggedIn();
    if (isAuthenticated) {
      // Get the Keycloak instance
      const keycloakInstance = this.keycloakAngular.getKeycloakInstance();

      // Check if idTokenParsed is defined
      if (keycloakInstance?.idTokenParsed) {

        this.keycloakAngular.loadUserProfile().then(profile => {
          if (profile?.firstName) {
            this.agentFirstName = profile?.firstName;
          }
          if (profile?.lastName) {
            this.agentLastName = profile?.lastName;
          }

        })
      }
    }

    this.userService
      .getUserInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dataSource) => {
        this.role = dataSource['role']['name'];
        this.id = dataSource['externalId'];
        this.userService
          .getUserPhoto(this.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((imageBlob: Blob) => {
            this.imageUrl = URL.createObjectURL(imageBlob);
          });
      });

    this.setActiveNavItem();

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setActiveNavItem();
    });
  }

  private setActiveNavItem(): void {
    const currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path;
    const activeNavItem = this.navList.find(item => item.routerLink === currentRoute);
    if (activeNavItem) {
      this.navService.setActiveItem(activeNavItem.routerLink);
    }
  }

  public navigateTo(routerLink: string): void {
    this.navService.setActiveItem(routerLink);
    this.router.navigateByUrl(routerLink);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showLogoutBtn(): void {
    this.showButtonLogout = !this.showButtonLogout;
  }

  public openDialog(): void {
     this.dialog.open(WebiatLogoutComponent, {
      width: '400px',
    });
  }

  @HostListener('document:click', ['$event.target']) onMouseClick(
    targetElement: any
  ) {
    const logoutWrapperClicked = document
      .getElementById('logoutWrapper')
      ?.contains(targetElement);
    const profileCardClicked = document
      .getElementById('profileCard')
      ?.contains(targetElement);
    if (!logoutWrapperClicked && !profileCardClicked) {
      this.showButtonLogout = false;
    }
  }
}
