import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-webiat-text-input',
  templateUrl: './webiat-text-input.component.html',
  styleUrls: ['./webiat-text-input.component.scss'],
})
export class WebiatTextInputComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() maxlength!: number;
  @Input() isLengthLimited: boolean = true;
  @Input() control: FormControl = new FormControl();
  @Input() showIcon: boolean = false;
  @Input() iconPath: string = '';
  @Input() inputType: string = 'text';
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false; // Nouvel attribut pour indiquer si le champ est requis
  @Output() inputChanged = new EventEmitter<string>();

  public value: string = '';
  public isFocused: boolean = false;
  public characterLimit: number = 60;
  public showCharacterCount: boolean = false;



  ngOnInit(): void {
    if (this.maxlength) {
      this.characterLimit = this.maxlength;
    }
  }

  public hasError(errorType: string): boolean {
    return this.control.hasError(errorType) && this.control.touched;
  }

  public onInputFocus(): void {
    this.isFocused = true;
    this.showCharacterCount = true;
  }

  onInputChanged(value: string) {
    let inputValue = value;
    // to remove leading spaces
    inputValue = inputValue.replace(/^\s+/, '');
    // to replace consecutive spaces with a single space
    inputValue = inputValue.replace(/\s{2,}/g, ' ');
    this.value = inputValue;
    this.inputChanged.emit(this.value);
    
    if (this.isLengthLimited && this.inputType === 'number'){
      if (this.isLengthLimited && inputValue.length > this.maxlength) {        
        inputValue = inputValue.substring(0, this.maxlength);        
        this.value = inputValue;
        this.inputChanged.emit(this.value);
      }

    }
  }

}
