<div class="input-wrapper" [ngClass]="{'input-has-icon': showIcon }">
  <label *ngIf="label">
    {{ label }} <span *ngIf="isRequired" class="required-asterisk">*</span>
  </label>
  <div *ngIf="showIcon" class="text-input-icon">
    <img class="text-input-icon-img" *ngIf="iconPath" [src]="iconPath" alt="Icon">
  </div>
  <input
    class="text-input"
    [type]="inputType"
    name="input-text"
    [maxlength]="isLengthLimited ? maxlength : null"
    [placeholder]="placeholder"
    [formControl]="control"
    (focus)="onInputFocus()"
    (blur)="control.markAsTouched()"
    (input)="onInputChanged(control.value)"
    [ngClass]="{
      'pass-char-number': isLengthLimited && control.value && control.value?.length >= characterLimit,
      'has-error': hasError('required'),
      'has-icon': showIcon,
      'number-input': inputType === 'number',
      'disabled': isDisabled
    }"
    autocomplete="off"
  />
  <div
    *ngIf="!isDisabled&&isLengthLimited && showCharacterCount && !hasError('required')"
    class="character-count"
  >
    <span *ngIf="characterLimit - control.value?.length > 0; else other_content">
      {{ characterLimit - control.value?.length }} caractères
    </span>
    <ng-template #other_content>
      Vous avez atteint la limite des caractères autorisés
    </ng-template>
  </div>
  <span *ngIf="hasError('required') && !isDisabled" class="error-message">
    Ce champ est requis*
  </span>
</div>
