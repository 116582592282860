import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from './core/guards/auth.guard';
import { DashboardComponent } from './shared/layouts/dashboard/dashboard.component';
import { WebiatDashboardContentComponent } from './features/webiat-dashboard-content/webiat-dashboard-content.component';
import { WebiatCardCreatorComponent } from './features/webiat-card-creator/webiat-card-creator.component';
import { WebiatAccessDeniedComponent } from './features/webiat-access-denied/webiat-access-denied.component';
import { NonStandardNotificationComponent } from './features/webbiat-notification-creator/non-standard-notification/non-standard-notification.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'access-denied', component: WebiatAccessDeniedComponent, canActivate: [AppAuthGuard] },
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'dashboard',
        component: WebiatDashboardContentComponent,
      },
      {
        path: 'content-managment',
        loadChildren: () =>
          import('./shared/components/content-managment/content-managment.module').then(
            (m) => m.ContentManagmentModule
          ),
      },
      {
        path: 'content-managment/news-card',
        component: WebiatCardCreatorComponent
      },
      {
        path:  'content-managment/edit-card/:id',
        component: WebiatCardCreatorComponent
      },
      {
        path: 'collaborator-list',
        loadChildren: () =>
          import('./shared/components/collaborator/collaborator.module').then(
            (m) => m.CollaboratorModule
          ),
      },
      {
        path: 'user-profil',
        loadChildren: () =>
          import('./shared/components/user-profil/user-profil.module').then(
            (m) => m.UserProfilModule
          ),
      },

      {
        path: 'non-standardized-notification-creator',
        component: NonStandardNotificationComponent,
      },
    ],
    canActivate: [AppAuthGuard],
    data: { roles: ['ROLE_ADMIN']}
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
