import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';


import {ModalComponent} from '../shared/components/modal/modal.component';
import {BehaviorSubject} from 'rxjs';
import {
  StandardNotificationComponent
} from '../features/webbiat-notification-creator/standard-notification/standard-notification.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  dialogRef: MatDialogRef<any> | undefined;
  confirmButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private selectedNotifType: string = "";

  constructor(private _modalService: MatDialog) { }

  toggleModal(
    title: string,
    textContent: string,
    cancelLabel: string,
    submitLabel: string,
    modalWidth: string,
    dynamicComponent: any = null,
    confirm: (() => void) | null = null,
    showCancelButton: boolean = true,
    btnIcon: string = '',
    props: any = null,
    isReversed: boolean = false
  ) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = modalWidth;

    const modalRef = this._modalService.open(ModalComponent, dialogConfig);


    modalRef.componentInstance.title = title;
    modalRef.componentInstance.textContent = textContent;
    modalRef.componentInstance.cancelLabel = cancelLabel;
    modalRef.componentInstance.submitLabel = submitLabel;
    modalRef.componentInstance.isReversed = isReversed;
    modalRef.componentInstance.showCancelButton = showCancelButton;
    modalRef.componentInstance.btnIcon = btnIcon;

    if (dynamicComponent) {
      modalRef.componentInstance.dynamicComponent = true;
      const componentRef = modalRef.componentInstance.modalBody.createComponent(dynamicComponent);
      const componentInstance: any = componentRef.instance;
      Object.assign(componentInstance, props);
    }

    modalRef.componentInstance.modalEvent.subscribe((confirmClicked: boolean) => {
      if (confirmClicked && confirm) {
        if (dynamicComponent === StandardNotificationComponent) {
          confirm();
      } else {
          confirm();
          modalRef.close();
      }
      }
    })

    modalRef.componentInstance.modalDismissEvent.subscribe((cancelBtnClicked: boolean) => {
      if (cancelBtnClicked) {
        modalRef.close();
      }
    })

  }

  getConfirmButtonDisabled() {
    return this.confirmButtonDisabled$.asObservable();
  }

  setConfirmButtonDisabled(confirmButtonDisabled: boolean) {
    this.confirmButtonDisabled$.next(confirmButtonDisabled);
  }

  setSelectedNotifType(value: string): void {
    this.selectedNotifType = value;
  }

  getSelectedNotifType(): string {
    return this.selectedNotifType;
  }

  setDialogRef(dialogRef: MatDialogRef<any>) {
    this.dialogRef = dialogRef;
  }

  updateModalSize(width: string, height?: string) {
    if (this.dialogRef) {
      this.dialogRef.updateSize(width, height);
    }
  }
}
